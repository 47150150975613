<template>
  <div>
    <quick-start-tip
      title="Servicios y Productos"
      content="En Samii puedes agregar o crear los servicios y/o productos que necesites para tus reparaciones,
      puedes editar el costo/precio y se vera relfejado automaticamente en el monto de la orden."
      placement="left"
      :bg-purple="false"
      :delay="200"
      :value="$store.state.quickStart.step === 4"
      @onNextStep="handleNextStep">
      <div class="space-y-2 py-4" :class="{ 'quick-start-selected': $store.state.quickStart.step === 4 }">
        <div class="w-full flex justify-between">
          <span class="font-bold text-md">Servicios de reparación</span>
          <a class="text-purple-400" @click="showService">
            <strong>+ Agregar nuevo servicio</strong>
          </a>
        </div>
        <div>
          <div class="flex flex-row space-x-2">
            <el-input
              type="text"
              v-model="serviceTextSearch"
              @input="showServicesDialog(true)">
              <i slot="prefix" class='mt-2 text-xl bx bx-search' />
            </el-input>
            <sa-button
              size="mini"
              type="secondary"
              @click="showServicesDialog(true)">
              Explorar
            </sa-button>
          </div>
        </div>
      </div>
    </quick-start-tip>
    <div
      v-for="(service, index) in orderDevice.orderDeviceServices"
      :key="`orderDeviceServices-${index}`"
      class="w-full border border-gray-200 rounded-md mb-2">
      <div>
        <div class="text-right">
          <button @click="removeService(index)">
            <i class="text-md bx bx-x" />
          </button>
        </div>
      </div>
      <el-form
        :ref="`service-${index}`"
        :model="service"
        :rules="serviceRules">
        <div class="w-full flex flex-col lg:flex-row lg:space-x-2 p-2 pt-0">
          <div class="w-full">
            <div>
              <strong>{{service.name}}</strong>
              <div>
                <el-input type="textarea" :rows="1" v-model="service.description" placeholder="Descripción del Servicio..." />
              </div>
            </div>
          </div>
          <div class="w-full lg:w-40">
            <span>Costo</span>
            <el-form-item prop="cost">
              <el-input
                v-if="$store.state.account.canEditOrdersPrices"
                v-model="service.cost"
                class="w-full el-input-text-right"
                type="number"
                placeholder="0.00" />
              <el-input
                disabled
                v-if="!$store.state.account.canEditOrdersPrices"
                placeholder="***" />
            </el-form-item>
          </div>
          <div class="w-full lg:w-40">
            <span>Precio</span>
            <el-input
              v-model="service.price"
              class="w-full el-input-text-right"
              type="number"
              placeholder="0.00" />
          </div>
        </div>
      </el-form>
    </div>
    <div
      v-if="hasOrderDeviceServices"
      class="py-2">
      <div class="mb-4">
        <el-checkbox
          v-model="orderDevice.hasWarranty">
          <span>
            {{ orderDevice.hasWarranty ? 'Días de garantía' : '¿Agregar tiempo de garantía?' }}
          </span>
        </el-checkbox>
      </div>
      <div class="w-1/3">
        <el-input
          v-if="orderDevice.hasWarranty"
          v-model="orderDevice.warrantyDays"
          type="number"
          class="align-middle"
          placeholder="0">
          <span
            class="el-input__icon"
            slot="suffix">
            día(s)
          </span>
        </el-input>
      </div>
    </div>

    <el-dialog
      :visible.sync="isVisibleSearchServicesDialog"
      :width="dialogClientWidth"
      @close="onCloseSearchServicesDialog">
      <div class="-mx-5">
        <device-services-search
          v-if="isVisibleSearchServicesDialog"
          :textSearch="serviceTextSearch"
          @cancel="showServicesDialog(false)"
          @onSelectServices="onSelectServices"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { state, mutations } from '../store';
import ServiceCommand, { Commands } from '@/components/dialogs/Service/commands';
import responsiveSize from '@/mixins/responsiveSize';
import { ServiceEnumDict } from '@/constants';
import _ from "lodash";
import CategoryDialogService, { command as CategoryDialogCommand, commands } from '@/components/dialogs/Category/service';
import CategoryEnum from '@/components/dialogs/Category/enum';
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';

export default {
  name: 'ServicesBlock',
  components: {
    DeviceServicesSearch: () => import('./ServicesSearch.vue'),
  },
  mixins: [
    responsiveSize,
  ],
  props: {
    orderDeviceIndex: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      dialogClientWidth: '40%',
      serviceTextSearch: null,
      isVisibleSearchServicesDialog: false,
      subscription: null,
      ServiceEnumDict,
      CategoryEnum,
      commandSubscription: null,
      serviceRules: {
        cost: [
          {
            required: true,
            validator(rule, value, callback) {
              if (value === null || value === undefined || value === '' || value < 0)
                callback(new Error('El costo debe ser 0 o un número mayor para continuar'));
            },
          },
        ],
      },
    };
  },
  created() {
    this.subscription = ServiceCommand.subscribe(this.commands);
    this.commandSubscription = CategoryDialogCommand.subscribe(this.customCommands);
    window.addEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  destroyed() {
    this.subscription.unsubscribe();
    this.commandSubscription.unsubscribe();
    window.removeEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  computed: {
    orderDevice() {
      return state.order.orderDevices[this.orderDeviceIndex];
    },
    hasOrderDeviceServices() {
      if (!this.orderDevice.orderDeviceServices) {
        return false;
      }
      return this.orderDevice.orderDeviceServices.length > 0;
    },
  },
  watch: {
    'orderDevice.orderDeviceServices': {
      deep: true,
      handler: _.debounce(async function () {
        const refs = Object.keys(this.$refs);
        for (let i = 0; i < refs.length; i++) {
          const form = this.$refs[refs[i]][0];
          if (form) form.validate().catch(() => {});
        }
      }, 300),
    },
  },
  methods: {
    customCommands({ type, payload }) {
      switch (type) {
        case commands.create:
          this.categoryCreate(payload);
          break;
        default:
      }
    },
    commands({ type, payload }) {
      switch(type) {
        case Commands.create:
          mutations.addOrderDeviceService(this.orderDeviceIndex, { id: payload.id, name: payload.name });
          break;
          default:
      }
    },
    removeService(orderDeviceServiceIndex) {
      mutations.removeOrderDeviceService(this.orderDeviceIndex, orderDeviceServiceIndex)
    },
    onCloseSearchServicesDialog() {
      this.serviceTextSearch = null;
    },
    showServicesDialog(show) {
      this.isVisibleSearchServicesDialog = show;
    },
    showService() {
      CategoryDialogService.show({ type: CategoryEnum.Service });
    },
    onSelectServices(selectedServices) {
      for (let i = 0; i < selectedServices.length; i++) {
        const service = selectedServices[i];
        mutations.addOrderDeviceService(this.orderDeviceIndex, { id: service.id, name: service.name });
      }
      this.showServicesDialog(false);
    },
    resizeDialogWidth() {
      this.dialogClientWidth = this.getPercentageWidth();
    },
    categoryCreate(category) {
      if (category.type === CategoryEnum.Service) {
        mutations.addOrderDeviceService(this.orderDeviceIndex, {id: category.id, name: category.name});
      }
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
    },
  },
};
</script>
